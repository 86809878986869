import * as React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components"
import { MainContainer, Video } from '../containers'
import YouTube from 'react-youtube';

class IndexPage extends React.Component {

  _onReady = (event) => {
    event.target.mute();
    event.target.setPlaybackQuality("hd1080");
    event.target.playVideo();
  }

  handleTicketsLink = (e) => {
    if (typeof window !== 'undefined') {
      window.open("https://lmp-billetterie.mapado.com/event/390913-concert-auto-psy");
    }
  }

  render() {
    
    const opts = {
      width: '100%',
      playerVars: {
        autoplay: 1,
        autohide: 1,
        loop: 1,
        disablekb: 1,
        fs: 0,
        modestbranding: 0,
        showinfo: 0,
        rel: 0,
        playlist: "N6FKFk5yjDI"
      },
    }

    const props = this.props;

    return (
      <Layout
        context={{
          title: 'Furioza',
          description: 'Furioza',
          ogimage: '',
          slug: '/'
        }}
        logo={props.data.logoWhite.publicURL}
      >

        <MainContainer className="invert">
          <div>
            <section>
              <div>
                <Video>
                  <YouTube
                    videoId={"N6FKFk5yjDI"}
                    opts={opts}
                    onReady={this._onReady}
                  />
                </Video>
              </div>
            </section>
          </div>
        </MainContainer>

        <MainContainer className="light">
          <div>
            <section>
              <div>
                <h1>FURIOZA</h1>
                <h2>AUTO-PSY</h2>
                <ul className="downloads">
                  <li>
                    <a href={`https://adorable-hotteok-dbcefd.netlify.app/documents/furioza-dossier-artistique.pdf`} target="_blank" rel="noreferrer">Télécharger le dossier artistique</a>
                  </li>
                  <li>
                    <a href={`https://adorable-hotteok-dbcefd.netlify.app/documents/furioza-epk.pdf`} target="_blank" rel="noreferrer">Télécharger l'EPK</a>
                  </li>
                </ul>
                <article>
                  <p><strong>FURIOZA C'EST UN MANTRA</strong></p>

                  <p>
                    FURIOZA C'EST L'EXPRESSION DE LA JUSTE COLÈRE.
                    C'EST NE PLUS S'ÉVERTUER À ARRONDIR LES ANGLES.

                    C'EST NE JAMAIS OUBLIER QUE C'EST CETTE LIMITE POSÉE LÀ, QUI EST ÉMANCIPATRICE.

                    C'EST LE TRANCHANT DES BLESSURES IRRÉMÉDIABLES.
                    C'EST LE FRACAS QUI DÉCHIRE L'INNOCENCE.

                    C'EST L'ULTRA-VIOLENCE DE LA RÉALITÉ EXPRIMÉE SANS FILTRE,

                    AVEC SINCÉRITÉ ET DÉRISION.

                    C'EST L'EXPRESSION D'UNE PUTAIN DE ROMANTIQUE !
                    FURIOZA DÉCLAME CE QUE LA RÉALITÉ,

                    LE FANTASME ET L'AMOUR ONT DE BRUTAL ET D'ABSOLU
                    EN EXACERBANT LES TRAITS DE CETTE TRAGIE-COMÉDIE

                    DONT LE RÉEL EST FAIT.

                    FURIOZA NOUS INVITE À PLONGER LORS DE CETTE PERFORMANCE

                    DANS SON UNIVERS MUSICAL ET VISUEL QU'ELLE QUALIFIE DE FÉÉRIE ÉPOUVANTE.
                  </p>

                  <p style={{ fontSize: "1.8rem", fontStyle: "italic", fontWeight: "bold" }}>
                    LA COLÈRE EST FONDATRICE DÈS LORS QU'ELLE EST EXPRIMÉE.
                    IL Y A DES DOUCES COLÈRES, FROIDES, EXPLOSIVES, SUBLIMES,
                    DES COLÈRES MAGMATIQUES, VOLCANIQUES, FONDATRICES ET CRÉATRICES.
                  </p>

                  <p>
                    FURIOZA NOUS EMBARQUE PAR SES PAROLES SCANDÉES
                    DANS UNE THÉÂTRALITÉ MUSICALE PURE ET CRUE,
                    MÊLÉE À DES ENVOLÉES LYRIQUE ET DE LA CHANSON RÉALISTE,
                    PARFOIS RAPPÉES, CHANTÉES, CRIÉES ET PARFOIS MÊME ELLE DÉ-RAPPE.

                    ELLE COMPOSE SES INSTRUMENTALES ENTRE HIPHOP,

                    RNB ALTERNATIF, ET DEATH HOP,

                    AGRÉMENTÉES DE BASSLINES ENVOÛTANTES ET DE RYTHMIQUES ENTÊTANTES.
                    AUTO-PSY EST UNE VARIATION DES ÉTATS D'ÂME DE CETTE DOUCE GUERRIÈRE
                    À LA CARAPACE DE CRISTAL FÊLÉE, AUX AILES D'ANGES AMOCHÉES.
                  </p>
                </article>
              </div>
            </section>
          </div>
        </MainContainer>

        <MainContainer className="light schedule">
          <article>
            <div>
              <span className="numbers">1 <small>et</small> 8</span>
              <span className="month">oct 2024</span>
              <span className="hour">20h30</span>
            </div>
            <div>
              <p>
                <span className="location">
                  Au <strong>Lavoir Moderne Parisien</strong>
                </span>
                <span className="address">
                  <address>35, rue Léon Paris XVIII<sup>e</sup></address>
                </span>
                <span className="phone">01 46 06 08 05</span>
                <span className="website">
                  <a href="https://www.lavoirmoderneparisien.com/">
                    www.lavoirmoderneparisien.com
                  </a>
                </span>
              </p>
            </div>
          </article>
          
          <div className="event-price">
            <div><strong>TARIF UNIQUE</strong> 10€ · <strong>DURÉE</strong> 1H</div>
            <div><button onClick={this.handleTicketsLink}>Réserver</button></div>
          </div>
        </MainContainer>
      </Layout>
    )
  }
}


export default IndexPage
export const pageQuery = graphql`
  query homePage {
    logoWhite: file(base: {eq: "furioza-trans-black.png"}) {
      publicURL
    }
    logoBlack: file(base: {eq: "furioza-black.png"}) {
      publicURL
    }
    splashScreen: file(base: {eq: "splash-screen.png"}) {
      publicURL
    }
  }
`